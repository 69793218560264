    <template>
    <div class="goods">
        <!-- <van-swipe class="goods-swipe" :autoplay="3000">
        <van-swipe-item v-for="thumb in goods.thumb" :key="thumb">
            <img  @click="handleClick" :src="thumb" >
        </van-swipe-item>
        </van-swipe> -->

        <div class="image-container">
            <img  style="width: -webkit-fill-available;" :src="thumb1" >
            <p class="p1">{{ zmc }}</p>
            <p class="p2">{{ zdj }}</p>
            <p class="p3">{{ zdttuzaiStr }}</p>
            <p class="p4">{{ zsn }}</p>
            <p class="p5">{{ zzxbz }}</p>
            <img  v-if="pcitcDis" :src="thumb" class="image"  @click="handleClick">
            <p class="p6">{{ zdttuzaiendStr }}</p>
            <p class="p7">{{ csrq }}</p>
            <p class="p8">{{ eh }}</p>
            <p class="p9">{{ xb }}</p>
            <p class="p10">{{ pz }}</p>
            <p class="p11">{{ fb }}</p>
            <p class="p12">{{ mb }}</p>
            <p class="p13">{{ fzr }}</p>
            <p class="p14">{{ myjl }}</p>
        </div>
    </div>
    </template>

    <script>
    // import dataDictionary from './dataDictionary'
    export default {
    components: {
    },
    data() {
        return {
            pageWidth:window.innerWidth,
            limitPageWidth:window.innerWidth,
        goods: {
            title: '牛肉',
            price: 2680,
            express: '免运费',

            remain: 19,
        }, // 简称
            zmc: '',
            // 等级
            zdj: '',
            // 装箱编号
            zzxbz: '',
            zsn: '',
            // 送宰时间
            zdttuzaiStr: '',
            zdttuzaiendStr: '',
            zdtchuchangStr: '',
            pcitcDis: false,
            supplier: '',
            zsfzbh: '',
            thumb1:require('../../assets/imgs/niu.png'),
            thumb: null,
            videoUrl: '',
            eh: '',//耳号
            csrq: '', // 出生日期
            xb: '', // 性别
            pz: '', // 品种
            fb: '', // 父本
            mb:'', // 母本
            myjl: '', // 免疫记录
            fzr: '' // 负责人
        };
    },
    computed:{
        $pxAuto(){
            return(value,maxWidth=540,standarWidth=375)=>{
                let pageWidth =this.pageWidth< maxWidth ? this.pageWidth : maxWidth
                this.limitPageWidth = pageWidth
                return value / standarWidth* pageWidth
            }
        }
    },
    mounted(){
        window.addEventListener("resize",()=> {
        this.pageWidth =window.innerWidth
        })
    },
    created() {
        this.findList();
    },
    methods: {
      findList() {
        // const queryParams = '</WCSN:K240001704181/></SPBH:10106070661/>'.split("/>")
        const queryParams = this.$route.query.scanCode.split("/>")
        window.console.log(queryParams)
        window.console.log(queryParams[0].substring(queryParams[0].indexOf(":")+1))
        if (queryParams.length > 1 && queryParams[1] && queryParams[1] !== "" ) {
            window.console.log(queryParams[1].substring(queryParams[1].indexOf(":")+1))
            this.pcitcDis = true
          try {
            let url = require("../../assets/imgs/"+ queryParams[1].substring(queryParams[1].indexOf(":")+1) +".jpg")
            this.thumb = url || null
          } catch (error) {
            this.$axios.get('/suyuan/SuYuan/getImgFile', { params:{ name: queryParams[1].substring(queryParams[1].indexOf(":")+1) } }).then( res => {
              if (res.data) {
                window.console.log('🚀 ~ Corresponding img source :', res.data[0])
                this.thumb = res.data[0].pic_path || null
              }
            });
          }
          // this.videoUrl = dataDictionary.getEnumTextByValue('parameter', queryParams[1].substring(queryParams[1].indexOf(":")+1)).result_data
          this.$axios.get('/suyuan/SuYuan/getVideoFile', { params:{ name: queryParams[1].substring(queryParams[1].indexOf(":")+1) } }).then( res => {
            if (res.data) {
              window.console.log('🚀 ~ Corresponding video source :', res.data)
              this.videoUrl = res.data
            }
          });

        }
        let params = {
            scanCode: queryParams[0].substring(queryParams[0].indexOf(":")+1)
        }

        this.$axios.get('/niu/hphnCmTuzai/queryNiuById', {params}).then( res => {
            window.console.log(res)
            if(res.data.code === 0) {
            //
            this.zmc = res.data.data.zmc
            this.zdj = res.data.data.zdj
            this.zzxbz = res.data.data.zzxbz
            this.zsn = res.data.data.zsn
            this.zdttuzaiStr = res.data.data.zdttuzaiStr
            this.zdttuzaiendStr= res.data.data.zdttuzaiendStr


            this.$axios.get('/eh/hphnCmTuzai/queryNiuById?eh='+res.data.data.zeb).then( res1 => {
                this.eh = res1.data.data.eh
                this.csrq = res1.data.data.csrq
                this.xb = res1.data.data.xb
                this.pz = res1.data.data.pz
                this.fb = res1.data.data.fb
                this.mb= res1.data.data.mb
                if (res1.data.data.myjl && res1.data.data.myjl != null && res1.data.data.myjl !== '') {
                    this.myjl= res1.data.data.myjl.substring(0,res1.data.data.myjl.indexOf(','))
                }
                this.fzr= res1.data.data.fzr

            })
            }
        });
      },
      timestampToDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getUTCFullYear();
        const month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // 月份是从0开始的
        const day = ('0' + date.getUTCDate()).slice(-2);
        const hours = ('0' + date.getUTCHours()).slice(-2);
        const minutes = ('0' + date.getUTCMinutes()).slice(-2);
        const seconds = ('0' + date.getUTCSeconds()).slice(-2);
        return `${year}${month}${day} ${hours}:${minutes}:${seconds}`;
      },
      handleClick() {
        if (this.videoUrl) {
          this.$router.push({
            path: 'cooking_video',
            query: {
              childValue: this.videoUrl
            }
          });
          // window.location.href = this.videoUrl;
        }
      },
      formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2);
      },

      onClickCart() {
      this.$router.push('cart');
      },
    }
    };
    </script>
    <style scoped>
    .goods {
        /* background-color: red; */
        /* height:190rem;
        background-repeat: no-repeat;
        background-image: url('https://cloud.bbrightway.com.cn/miniodata/20231212/0785a89aebde4cdc9f47f14f742d6f39.jpg');
        background-size:contain */
    }
    .image-container {
    position: relative;
    text-align:right;
    }
    .image-container img {
    display: block;
    }
    .image-container .p1 {
    position: absolute;
    top: 31.6%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p2 {
    position: absolute;
    top: 32.66%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p3 {
    position: absolute;
    top: 33.71%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p4 {
    position: absolute;
    top: 34.79%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p5 {
    position: absolute;
    top: 35.84%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }

    .image-container .p6 {
    position: absolute;
    top: 45.33%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }

    .image-container .image {
    position: absolute;
    top: 20.3%;
    border: 0;
    height: 250px;
    width: 330px;
    border-radius:6px;
    vertical-align: middle;
    transform: translate(7%, -50%);
    }
    .image-container .p6 {
    position: absolute;
    top: 45.33%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p7 {
    position: absolute;
    top: 58.08%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p8 {
    position: absolute;
    top:59.13%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p9 {
    position: absolute;
    top: 60.15%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p10 {
    position: absolute;
    top: 61.25%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p11 {
    position: absolute;
    top: 62.28%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p12 {
    position: absolute;
    top: 63.33%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p13 {
    position: absolute;
    top: 65.43%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    .image-container .p14 {
    position: absolute;
    top: 64.43%;
    color: #302B3F;
    right: 0;
    font-size: 14px;
    width: 200px;
    transform: translate(-30%, -50%);
    }
    </style>
    <!-- <style lang="less">
    .goods {
    //   padding-bottom: 50px;
    &-swipe {
        img {
        width: 100%;
        display: block;
        }
    }

    &-title {
        font-size: 16px;
    }

    &-price {
        color: #f44;
    }

    &-express {
        color: #999;
        font-size: 12px;
        padding: 5px 15px;
    }

    &-cell-group {
        margin: 15px 0;

        .van-cell__value {
        color: #999;
        }
    }

    &-tag {
        margin-left: 5px;
    }
    } -->
    <!-- </style> -->
